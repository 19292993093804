<template>
<section id="bigredbutton">
  <div id="main-content" class="container-fluid">
    <div class="row h-100">
      <div class="col-md-8 col-xl-9 col-sl-9"></div>
      <div class="col-md-4 col-xl-3 col-sl-3 d-flex h-100 align-items-center">
        <div class="wrapper">
          <div class="draw-line-wrapper inview animated slow">
            <div class="draw-line"><span class="title"><img src="@/assets/line-purple.png" class="img-fluid" /></span></div>
          </div>
          <h1 class="heading inview animated delay-1">Big Red Button Entertainment</h1>
          <div class="content inview animated delay-2 slow">
            <p>We are thrilled to partner with Big Red Button, and their external studio teams, to develop Clementine’s Nightmare–the game. Building an incredible AAA gaming experience takes an exceptionally
              talented, multi-studio approach; and that is exactly what we are going to do to bring the Clementine’s Nightmare game into the world.</p>
            <a class="btn btn-theme btn-white" href="http://brbent.com/" target="_blank" rel="noopener noreferrer">VIEW STUDIO</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</template>

<script>
import helpers from '@/scripts/helpers.js'
export default {
  name: 'BigRedButton',
  mounted() {
    helpers.initWaypoint();
    document.body.classList.add('page-bigredbutton')
  },
}
</script>
<style lang="scss" scoped>
body.page-bigredbutton {
    #bigredbutton {
        background: url("../assets/Clementine_Website_2.5.jpg") no-repeat center center / cover;
        position: relative;
        height: 100vh;
        width: 100%;
        @media(max-width: 1799px) {
            background-position: 80% center;
        }
        @media(max-width: 1199px) and (max-height: 999px) {
            background: url("../assets/Clementine_Website_2.5_tablet.jpg") no-repeat center center / cover;
        }
        @media(max-width: 1199px) and (min-height: 1000px) {
            height: 90vh;
            background: url("../assets/Clementine_Website_mobile.jpg") no-repeat center center / cover;
        }
        @media(max-width: 1080px) {
            height: 90vh;
            min-height: 1000px;
            background: url("../assets/Clementine_Website_mobile.jpg") no-repeat center center / cover;
        }
        @media(max-width: 767px) {
            height: 85vh;
            min-height: 800px;
        }
        @media(max-width: 499px) {
            height: 85vh;
            min-height: 740px;
            background: url("../assets/Clementine_Website_phone.jpg") no-repeat center center / cover;
        }
        #main-content {
            width: 100%;
            height: 100%;
            color: #000;
            .col-md-8 {
                @media(max-width: 1080px) {
                    display: none !important;
                }
            }
            .col-md-4 {
                @media(max-width: 1080px), (max-width: 1199px) and (min-height: 1000px) {
                    display: block !important;
                    max-width: 80%;
                    flex: auto;
                    .wrapper {
                        width: auto;
                        max-width: 40rem;
                        padding: 0 2rem;
                        margin: 7rem auto 0;
                    }
                }
                @media(max-width: 499px) {
                    max-width: inherit;
                    .wrapper {
                        padding: 0 1rem;
                    }
                }
            }
            .heading {
                font-size: 2.6rem;
                color: #000;
                font-weight: 300;
                @media(max-width: 1199px) {
                    font-size: 2.3rem;
                }
                @media(max-width: 991px) {
                    font-size: 2rem;
                }
            }
            .wrapper {
                margin-top: -8vw;
                width: 24rem;
                padding-right: 3vw;
                @media(max-width: 1199px) {
                    margin-top: -14vw;
                }
            }
            .btn-white {
                font-size: 1rem;
                font-weight: 300;
            }
            .content {
                font-size: 0.9rem;
                @media(max-width: 1080px) {
                    font-size: 1rem;

                }
            }
            .draw-line-wrapper {
                max-width: 18rem;
                @media(max-width: 1199px) {
                    max-width: 15rem;
                }
                @media(max-width: 991px) {
                    max-width: 12rem;
                }
                .draw-line {
                    @media(max-width: 1199px) {
                        padding-left: 1.2rem;
                        padding-right: 1.2rem;
                        margin-bottom: 0.5rem;
                    }
                    &:after,
                    &:before {
                        border: 3px solid #6300dd;
                        top: 0.9rem;
                        height: 1rem;
                        width: 1rem;
                        @media(max-width: 1199px) {
                            border: 2px solid #6300dd;
                            height: 0.8rem;
                            width: 0.8rem;
                            top: 0.95rem;
                        }
                    }
                    img {
                        max-width: 5rem;
                        margin-top: 0.9rem;
                        @media(max-width: 1199px) {
                            max-width: 4rem;
                            margin-top: 1rem;
                        }
                    }
                    span {
                        &:after,
                        &:before {
                            border-top: 2px solid #6300dd;
                            margin: 1.3rem 1rem 0 0;
                            flex: 1 0 1rem;
                            @media(max-width: 1199px) {
                                margin: 1.3rem 0.6rem 0 0;
                                flex: 1 0 0.6rem;
                            }
                        }
                        &:after {
                            margin: 1.3rem 0 0 1rem;
                            flex: 1 0 0 1rem;
                            @media(max-width: 1199px) {
                                margin: 1.3rem 0 0 0.6rem;
                                flex: 1 0 0 0.6rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>

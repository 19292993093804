<template>
<div id="home">
  <section id="hero">
    <Hero class="vh-90 full-video" title="Story of Clementine" titleClass="light-pink" bkgImage="hero_bkg.jpg" iconImage="book_icon.png" bkgColor="#17111e" positionClass="mb-5" bkgVideoFull="697128529?h=d79a1ecb64">
      <p>Welcome to Clementine’s Nightmare! Clementine’s universe takes a one-of-a-kind turn for the worse when her world is invaded by nightmarish creatures from other realms of reality. Her town, and the people she once knew are all gone, having
        become twisted by darkness. But just when all hope seems lost, powerful forces of the light arrive from across existence to give Clementine and her friends a uniquely thrilling second chance.</p>
    </Hero>
  </section>
  <section id="features">
    <div class="features-wrapper container-xl">
      <div class="card-group">
        <div class="card">
          <a href="https://www.webtoons.com/en/challenge/clementines-nightmare/list?title_no=768515" title="Project Neighborhood" rel="noopener noreferrer">
            <div class="card-img-top">
              <img class="img-fluid" src="@/assets/feature_nightmare_appears.png" alt="Project Neighborhood">
            </div>
            <div class="card-body text-center">
              <h4 class="card-title">Project Neighborhood</h4>
              <p class="card-text">Follow the journey of Clementine as her town descends into nightmares.</p>
            </div>
            <div class="card-footer text-center"></div>
          </a>
        </div>
        <div class="card">
          <a href="#characters" title="Characters">
            <div class="card-img-top">
              <img class="img-fluid" src="@/assets/featured_clementines.png" alt="Card image cap">
            </div>
            <div class="card-body text-center">
              <h3 class="card-title">A new being appears</h3>
              <p class="card-text">See the ever expanding world of Clementine. Creatures from all realms of existence vie for dominance of our world -- take a closer look at who they are.</p>
            </div>
            <div class="card-footer text-center"></div>
          </a>
        </div>
        <div class="card">
          <router-link to="/the-journey" @click="scrollToTop">
            <div class="card-img-top">
              <img class="img-fluid" src="@/assets/featured_journey.png" alt="Card image cap">
            </div>
            <div class="card-body text-center">
              <h3 class="card-title">The journey ahead</h3>
              <p class="card-text">We are excited to share with our community where we plan on taking Clementine's Nightmare. Get ready for the excitement to come and the surprises we have in store.</p>
            </div>
            <div class="card-footer text-center"></div>
          </router-link>
        </div>
      </div>
    </div>
  </section>
  <section id="highlights" class="section">
    <div class="embed-responsive embed-responsive-16by9 d-none d-md-block">
      <iframe id="video-hero" src="https://player.vimeo.com/video/697128513?h=5ea44ea81d&title=0&api=1&background=1&autoplay=1&loop=1&byline=0&title=0" width="100%" height="100%" frameborder="0" webkitallowfullscreen="" mozallowfullscreen=""
        allowfullscreen="" allow="autoplay" data-ready="true"></iframe>
    </div>
    <div class="d-md-none"><img src="@/assets/highlight_bkg.jpg" class="img-fluid" alt="DEAL EM OUT"></div>
    <Highlights />
  </section>
  <section id="characters">
    <Characters />
  </section>
  <section id="main-heading">
    <div class="headline w-70 container-xl">
      <div class="row">
        <div class="col-md-6">
          <h2 class="inview animated delay-1"><span>PROJECT NEIGHBORHOOD</span> IS THE HYPER LIMITED 3D COLLECTION FROM CLEMENTINE’S NIGHTMARE</h2>
          <div class="draw-line-wrapper inview animated delay-2">
            <div class="draw-line"><span class="title"><img src="@/assets/line-tan.png" class="img-fluid" /></span></div>
          </div>
          <h4 class="inview animated delay-2">THIS EXCLUSIVE 3D PROJECT WILL BE BOTH AN IN-GAME SKIN AND A METAVERSE-READY 3D PFP FOR ALL GENESIS & ECLIPSE HOLDERS</h4>
        </div>
      </div>
    </div>
  </section>
  <section id="grid-header">
    <div class="grid">
      <div class="grid-item grid-1 grid-item--width2">
        <div class="d-flex h-100 align-items-center">
          <div class="inner-cell">
            <div class="draw-line-wrapper">
              <div class="draw-line"><span class="title"><img src="@/assets/line-tan.png" class="img-fluid" /></span></div>
            </div>
            <h2 class="inview animated delay-1 slow mb-0" data-ease="fadeIn">ALL HOLDERS WILL DIRECTLY UNLOCK THE CLEMENTINE’S GAME EXPERIENCE</h2>
            <div class="draw-line-wrapper">
              <div class="draw-line"><span class="title"><img src="@/assets/line-tan.png" class="img-fluid" /></span></div>
            </div>
            <div class="draw-button-next"></div>
          </div>
        </div>
      </div>
      <div class="grid-item grid-2 grid-item--height2">
        <div class="d-flex h-100 align-items-center">
          <div class="inner-cell">
            <img src="@/assets/home_grid_2.png" class="img-fluid" />
            <div class="icon-svg mb-3"><svg class="img-fluid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="85px" height="17px" viewBox="0 0 85 17">
                <path fill-rule="evenodd" fill="#ffc75a"
                  d="M84.203,16.126 C83.664,16.244 83.104,16.309 82.529,16.309 C78.227,16.309 74.740,12.815 74.740,8.506 C74.740,4.197 78.227,0.704 82.529,0.704 C83.104,0.704 83.664,0.768 84.203,0.887 C80.707,1.654 78.089,4.773 78.089,8.506 C78.089,12.239 80.707,15.358 84.203,16.126 ZM64.110,16.309 C59.808,16.309 56.321,12.815 56.321,8.506 C56.321,4.197 59.808,0.704 64.110,0.704 C65.327,0.704 66.478,0.983 67.504,1.482 C64.903,2.745 63.109,5.416 63.109,8.506 C63.109,11.596 64.903,14.267 67.504,15.531 C66.478,16.029 65.327,16.309 64.110,16.309 ZM42.523,16.309 C38.221,16.309 34.734,12.815 34.734,8.506 C34.734,7.429 34.952,6.403 35.346,5.469 C35.374,5.402 35.406,5.336 35.436,5.270 C35.458,5.221 35.479,5.172 35.502,5.123 C36.760,2.509 39.431,0.704 42.523,0.704 C45.615,0.704 48.286,2.509 49.544,5.123 C49.568,5.172 49.588,5.221 49.611,5.270 C49.641,5.336 49.672,5.402 49.700,5.469 C50.095,6.403 50.313,7.429 50.313,8.506 C50.313,12.815 46.825,16.309 42.523,16.309 ZM20.937,16.309 C19.720,16.309 18.568,16.029 17.542,15.531 C20.144,14.267 21.938,11.596 21.938,8.506 C21.938,5.416 20.144,2.745 17.542,1.482 C18.568,0.983 19.720,0.704 20.937,0.704 C25.239,0.704 28.726,4.197 28.726,8.506 C28.726,12.815 25.239,16.309 20.937,16.309 ZM2.518,16.309 C1.943,16.309 1.383,16.244 0.843,16.126 C4.340,15.358 6.958,12.239 6.958,8.506 C6.958,4.773 4.340,1.654 0.843,0.887 C1.383,0.768 1.943,0.704 2.518,0.704 C6.820,0.704 10.307,4.197 10.307,8.506 C10.307,12.815 6.820,16.309 2.518,16.309 Z">
                </path>
              </svg>
            </div>
            <h4 class="mb-0 text-center mb-0 inview animated delay-1">Your individual NFT represents an active piece of the in-development game.</h4>
          </div>
        </div>
      </div>
      <div class="grid-item grid-3">
        <div class="cell-border cell-black text-center black">
          <div class="icon-svg"><svg class="img-fluid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="85px" height="17px" viewBox="0 0 85 17">
              <path fill-rule="evenodd" fill="#000"
                d="M84.203,16.126 C83.664,16.244 83.104,16.309 82.529,16.309 C78.227,16.309 74.740,12.815 74.740,8.506 C74.740,4.197 78.227,0.704 82.529,0.704 C83.104,0.704 83.664,0.768 84.203,0.887 C80.707,1.654 78.089,4.773 78.089,8.506 C78.089,12.239 80.707,15.358 84.203,16.126 ZM64.110,16.309 C59.808,16.309 56.321,12.815 56.321,8.506 C56.321,4.197 59.808,0.704 64.110,0.704 C65.327,0.704 66.478,0.983 67.504,1.482 C64.903,2.745 63.109,5.416 63.109,8.506 C63.109,11.596 64.903,14.267 67.504,15.531 C66.478,16.029 65.327,16.309 64.110,16.309 ZM42.523,16.309 C38.221,16.309 34.734,12.815 34.734,8.506 C34.734,7.429 34.952,6.403 35.346,5.469 C35.374,5.402 35.406,5.336 35.436,5.270 C35.458,5.221 35.479,5.172 35.502,5.123 C36.760,2.509 39.431,0.704 42.523,0.704 C45.615,0.704 48.286,2.509 49.544,5.123 C49.568,5.172 49.588,5.221 49.611,5.270 C49.641,5.336 49.672,5.402 49.700,5.469 C50.095,6.403 50.313,7.429 50.313,8.506 C50.313,12.815 46.825,16.309 42.523,16.309 ZM20.937,16.309 C19.720,16.309 18.568,16.029 17.542,15.531 C20.144,14.267 21.938,11.596 21.938,8.506 C21.938,5.416 20.144,2.745 17.542,1.482 C18.568,0.983 19.720,0.704 20.937,0.704 C25.239,0.704 28.726,4.197 28.726,8.506 C28.726,12.815 25.239,16.309 20.937,16.309 ZM2.518,16.309 C1.943,16.309 1.383,16.244 0.843,16.126 C4.340,15.358 6.958,12.239 6.958,8.506 C6.958,4.773 4.340,1.654 0.843,0.887 C1.383,0.768 1.943,0.704 2.518,0.704 C6.820,0.704 10.307,4.197 10.307,8.506 C10.307,12.815 6.820,16.309 2.518,16.309 Z">
              </path>
            </svg>
          </div>
          <div class="d-flex h-100 align-items-center">
            <p class="mb-0 inview animated delay-1">Genesis & Eclipse holders will be building the in-game economy and will have the opportunity to influence and run the market at launch.</p>
          </div>
          <div class="icon-svg bottom"><svg class="img-fluid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="85px" height="17px" viewBox="0 0 85 17">
              <path fill-rule="evenodd" fill="#000"
                d="M84.203,16.126 C83.664,16.244 83.104,16.309 82.529,16.309 C78.227,16.309 74.740,12.815 74.740,8.506 C74.740,4.197 78.227,0.704 82.529,0.704 C83.104,0.704 83.664,0.768 84.203,0.887 C80.707,1.654 78.089,4.773 78.089,8.506 C78.089,12.239 80.707,15.358 84.203,16.126 ZM64.110,16.309 C59.808,16.309 56.321,12.815 56.321,8.506 C56.321,4.197 59.808,0.704 64.110,0.704 C65.327,0.704 66.478,0.983 67.504,1.482 C64.903,2.745 63.109,5.416 63.109,8.506 C63.109,11.596 64.903,14.267 67.504,15.531 C66.478,16.029 65.327,16.309 64.110,16.309 ZM42.523,16.309 C38.221,16.309 34.734,12.815 34.734,8.506 C34.734,7.429 34.952,6.403 35.346,5.469 C35.374,5.402 35.406,5.336 35.436,5.270 C35.458,5.221 35.479,5.172 35.502,5.123 C36.760,2.509 39.431,0.704 42.523,0.704 C45.615,0.704 48.286,2.509 49.544,5.123 C49.568,5.172 49.588,5.221 49.611,5.270 C49.641,5.336 49.672,5.402 49.700,5.469 C50.095,6.403 50.313,7.429 50.313,8.506 C50.313,12.815 46.825,16.309 42.523,16.309 ZM20.937,16.309 C19.720,16.309 18.568,16.029 17.542,15.531 C20.144,14.267 21.938,11.596 21.938,8.506 C21.938,5.416 20.144,2.745 17.542,1.482 C18.568,0.983 19.720,0.704 20.937,0.704 C25.239,0.704 28.726,4.197 28.726,8.506 C28.726,12.815 25.239,16.309 20.937,16.309 ZM2.518,16.309 C1.943,16.309 1.383,16.244 0.843,16.126 C4.340,15.358 6.958,12.239 6.958,8.506 C6.958,4.773 4.340,1.654 0.843,0.887 C1.383,0.768 1.943,0.704 2.518,0.704 C6.820,0.704 10.307,4.197 10.307,8.506 C10.307,12.815 6.820,16.309 2.518,16.309 Z">
              </path>
            </svg></div>
        </div>
      </div>
      <div class="grid-item grid-4">
        <div class="cell-border cell-black text-center black">
          <div class="icon-svg"><svg class="img-fluid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="85px" height="17px" viewBox="0 0 85 17">
              <path fill-rule="evenodd" fill="#000"
                d="M84.203,16.126 C83.664,16.244 83.104,16.309 82.529,16.309 C78.227,16.309 74.740,12.815 74.740,8.506 C74.740,4.197 78.227,0.704 82.529,0.704 C83.104,0.704 83.664,0.768 84.203,0.887 C80.707,1.654 78.089,4.773 78.089,8.506 C78.089,12.239 80.707,15.358 84.203,16.126 ZM64.110,16.309 C59.808,16.309 56.321,12.815 56.321,8.506 C56.321,4.197 59.808,0.704 64.110,0.704 C65.327,0.704 66.478,0.983 67.504,1.482 C64.903,2.745 63.109,5.416 63.109,8.506 C63.109,11.596 64.903,14.267 67.504,15.531 C66.478,16.029 65.327,16.309 64.110,16.309 ZM42.523,16.309 C38.221,16.309 34.734,12.815 34.734,8.506 C34.734,7.429 34.952,6.403 35.346,5.469 C35.374,5.402 35.406,5.336 35.436,5.270 C35.458,5.221 35.479,5.172 35.502,5.123 C36.760,2.509 39.431,0.704 42.523,0.704 C45.615,0.704 48.286,2.509 49.544,5.123 C49.568,5.172 49.588,5.221 49.611,5.270 C49.641,5.336 49.672,5.402 49.700,5.469 C50.095,6.403 50.313,7.429 50.313,8.506 C50.313,12.815 46.825,16.309 42.523,16.309 ZM20.937,16.309 C19.720,16.309 18.568,16.029 17.542,15.531 C20.144,14.267 21.938,11.596 21.938,8.506 C21.938,5.416 20.144,2.745 17.542,1.482 C18.568,0.983 19.720,0.704 20.937,0.704 C25.239,0.704 28.726,4.197 28.726,8.506 C28.726,12.815 25.239,16.309 20.937,16.309 ZM2.518,16.309 C1.943,16.309 1.383,16.244 0.843,16.126 C4.340,15.358 6.958,12.239 6.958,8.506 C6.958,4.773 4.340,1.654 0.843,0.887 C1.383,0.768 1.943,0.704 2.518,0.704 C6.820,0.704 10.307,4.197 10.307,8.506 C10.307,12.815 6.820,16.309 2.518,16.309 Z">
              </path>
            </svg>
          </div>
          <div class="d-flex h-100 align-items-center">
            <div class="wrapper">
              <img src="@/assets/home_grid_4.png" class="img-fluid" />
              <p class="mb-0 inview animated delay-1">All holders will have first priority on abilities, skins, characters, and weaponry.</p>
            </div>
          </div>
          <div class="icon-svg bottom"><svg class="img-fluid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="85px" height="17px" viewBox="0 0 85 17">
              <path fill-rule="evenodd" fill="#000"
                d="M84.203,16.126 C83.664,16.244 83.104,16.309 82.529,16.309 C78.227,16.309 74.740,12.815 74.740,8.506 C74.740,4.197 78.227,0.704 82.529,0.704 C83.104,0.704 83.664,0.768 84.203,0.887 C80.707,1.654 78.089,4.773 78.089,8.506 C78.089,12.239 80.707,15.358 84.203,16.126 ZM64.110,16.309 C59.808,16.309 56.321,12.815 56.321,8.506 C56.321,4.197 59.808,0.704 64.110,0.704 C65.327,0.704 66.478,0.983 67.504,1.482 C64.903,2.745 63.109,5.416 63.109,8.506 C63.109,11.596 64.903,14.267 67.504,15.531 C66.478,16.029 65.327,16.309 64.110,16.309 ZM42.523,16.309 C38.221,16.309 34.734,12.815 34.734,8.506 C34.734,7.429 34.952,6.403 35.346,5.469 C35.374,5.402 35.406,5.336 35.436,5.270 C35.458,5.221 35.479,5.172 35.502,5.123 C36.760,2.509 39.431,0.704 42.523,0.704 C45.615,0.704 48.286,2.509 49.544,5.123 C49.568,5.172 49.588,5.221 49.611,5.270 C49.641,5.336 49.672,5.402 49.700,5.469 C50.095,6.403 50.313,7.429 50.313,8.506 C50.313,12.815 46.825,16.309 42.523,16.309 ZM20.937,16.309 C19.720,16.309 18.568,16.029 17.542,15.531 C20.144,14.267 21.938,11.596 21.938,8.506 C21.938,5.416 20.144,2.745 17.542,1.482 C18.568,0.983 19.720,0.704 20.937,0.704 C25.239,0.704 28.726,4.197 28.726,8.506 C28.726,12.815 25.239,16.309 20.937,16.309 ZM2.518,16.309 C1.943,16.309 1.383,16.244 0.843,16.126 C4.340,15.358 6.958,12.239 6.958,8.506 C6.958,4.773 4.340,1.654 0.843,0.887 C1.383,0.768 1.943,0.704 2.518,0.704 C6.820,0.704 10.307,4.197 10.307,8.506 C10.307,12.815 6.820,16.309 2.518,16.309 Z">
              </path>
            </svg></div>
        </div>
      </div>
      <div class="grid-item grid-5 grid-item--width2 grid-item--height2 px-0 py-0">
        <div class="d-flex h-100 align-items-center">
          <div class="inner-cell px-0 py-0 text-center">
            <img class="img-fluid" src="@/assets/home_grid_5.jpg" alt="Your Project Neighborhood 3D PFP will migrate in-game as your base skin at game launch.">
            <div class="icon-svg larger"><svg class="img-fluid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="85px" height="17px" viewBox="0 0 85 17">
                <path fill-rule="evenodd" fill="#ffc75a"
                  d="M84.203,16.126 C83.664,16.244 83.104,16.309 82.529,16.309 C78.227,16.309 74.740,12.815 74.740,8.506 C74.740,4.197 78.227,0.704 82.529,0.704 C83.104,0.704 83.664,0.768 84.203,0.887 C80.707,1.654 78.089,4.773 78.089,8.506 C78.089,12.239 80.707,15.358 84.203,16.126 ZM64.110,16.309 C59.808,16.309 56.321,12.815 56.321,8.506 C56.321,4.197 59.808,0.704 64.110,0.704 C65.327,0.704 66.478,0.983 67.504,1.482 C64.903,2.745 63.109,5.416 63.109,8.506 C63.109,11.596 64.903,14.267 67.504,15.531 C66.478,16.029 65.327,16.309 64.110,16.309 ZM42.523,16.309 C38.221,16.309 34.734,12.815 34.734,8.506 C34.734,7.429 34.952,6.403 35.346,5.469 C35.374,5.402 35.406,5.336 35.436,5.270 C35.458,5.221 35.479,5.172 35.502,5.123 C36.760,2.509 39.431,0.704 42.523,0.704 C45.615,0.704 48.286,2.509 49.544,5.123 C49.568,5.172 49.588,5.221 49.611,5.270 C49.641,5.336 49.672,5.402 49.700,5.469 C50.095,6.403 50.313,7.429 50.313,8.506 C50.313,12.815 46.825,16.309 42.523,16.309 ZM20.937,16.309 C19.720,16.309 18.568,16.029 17.542,15.531 C20.144,14.267 21.938,11.596 21.938,8.506 C21.938,5.416 20.144,2.745 17.542,1.482 C18.568,0.983 19.720,0.704 20.937,0.704 C25.239,0.704 28.726,4.197 28.726,8.506 C28.726,12.815 25.239,16.309 20.937,16.309 ZM2.518,16.309 C1.943,16.309 1.383,16.244 0.843,16.126 C4.340,15.358 6.958,12.239 6.958,8.506 C6.958,4.773 4.340,1.654 0.843,0.887 C1.383,0.768 1.943,0.704 2.518,0.704 C6.820,0.704 10.307,4.197 10.307,8.506 C10.307,12.815 6.820,16.309 2.518,16.309 Z">
                </path>
              </svg>
            </div>
            <h3 class="mb-0 mt-3 px-4 pb-4 inview animated delay-1">Your Project Neighborhood 3D PFP will migrate <span class="light-pink">in-game</span> as your base skin at game launch.</h3>
          </div>
        </div>
      </div>
      <div class="grid-item grid-6 grid-item--width2">
        <div class="cell-border">
          <div class="icon-svg"><svg class="img-fluid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="85px" height="17px" viewBox="0 0 85 17">
              <path fill-rule="evenodd" fill="#ffc75a"
                d="M84.203,16.126 C83.664,16.244 83.104,16.309 82.529,16.309 C78.227,16.309 74.740,12.815 74.740,8.506 C74.740,4.197 78.227,0.704 82.529,0.704 C83.104,0.704 83.664,0.768 84.203,0.887 C80.707,1.654 78.089,4.773 78.089,8.506 C78.089,12.239 80.707,15.358 84.203,16.126 ZM64.110,16.309 C59.808,16.309 56.321,12.815 56.321,8.506 C56.321,4.197 59.808,0.704 64.110,0.704 C65.327,0.704 66.478,0.983 67.504,1.482 C64.903,2.745 63.109,5.416 63.109,8.506 C63.109,11.596 64.903,14.267 67.504,15.531 C66.478,16.029 65.327,16.309 64.110,16.309 ZM42.523,16.309 C38.221,16.309 34.734,12.815 34.734,8.506 C34.734,7.429 34.952,6.403 35.346,5.469 C35.374,5.402 35.406,5.336 35.436,5.270 C35.458,5.221 35.479,5.172 35.502,5.123 C36.760,2.509 39.431,0.704 42.523,0.704 C45.615,0.704 48.286,2.509 49.544,5.123 C49.568,5.172 49.588,5.221 49.611,5.270 C49.641,5.336 49.672,5.402 49.700,5.469 C50.095,6.403 50.313,7.429 50.313,8.506 C50.313,12.815 46.825,16.309 42.523,16.309 ZM20.937,16.309 C19.720,16.309 18.568,16.029 17.542,15.531 C20.144,14.267 21.938,11.596 21.938,8.506 C21.938,5.416 20.144,2.745 17.542,1.482 C18.568,0.983 19.720,0.704 20.937,0.704 C25.239,0.704 28.726,4.197 28.726,8.506 C28.726,12.815 25.239,16.309 20.937,16.309 ZM2.518,16.309 C1.943,16.309 1.383,16.244 0.843,16.126 C4.340,15.358 6.958,12.239 6.958,8.506 C6.958,4.773 4.340,1.654 0.843,0.887 C1.383,0.768 1.943,0.704 2.518,0.704 C6.820,0.704 10.307,4.197 10.307,8.506 C10.307,12.815 6.820,16.309 2.518,16.309 Z">
              </path>
            </svg>
          </div>
          <div class="d-flex h-100 align-items-center">
            <div class="wrapper inview animated delay-1">
              <h4>PFPs are Metaverse-ready for all platforms that support <span class="light-pink">high-definition 3D assets.</span></h4>
            </div>
          </div>
          <div class="icon-svg bottom"><svg class="img-fluid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="85px" height="17px" viewBox="0 0 85 17">
              <path fill-rule="evenodd" fill="#ffc75a"
                d="M84.203,16.126 C83.664,16.244 83.104,16.309 82.529,16.309 C78.227,16.309 74.740,12.815 74.740,8.506 C74.740,4.197 78.227,0.704 82.529,0.704 C83.104,0.704 83.664,0.768 84.203,0.887 C80.707,1.654 78.089,4.773 78.089,8.506 C78.089,12.239 80.707,15.358 84.203,16.126 ZM64.110,16.309 C59.808,16.309 56.321,12.815 56.321,8.506 C56.321,4.197 59.808,0.704 64.110,0.704 C65.327,0.704 66.478,0.983 67.504,1.482 C64.903,2.745 63.109,5.416 63.109,8.506 C63.109,11.596 64.903,14.267 67.504,15.531 C66.478,16.029 65.327,16.309 64.110,16.309 ZM42.523,16.309 C38.221,16.309 34.734,12.815 34.734,8.506 C34.734,7.429 34.952,6.403 35.346,5.469 C35.374,5.402 35.406,5.336 35.436,5.270 C35.458,5.221 35.479,5.172 35.502,5.123 C36.760,2.509 39.431,0.704 42.523,0.704 C45.615,0.704 48.286,2.509 49.544,5.123 C49.568,5.172 49.588,5.221 49.611,5.270 C49.641,5.336 49.672,5.402 49.700,5.469 C50.095,6.403 50.313,7.429 50.313,8.506 C50.313,12.815 46.825,16.309 42.523,16.309 ZM20.937,16.309 C19.720,16.309 18.568,16.029 17.542,15.531 C20.144,14.267 21.938,11.596 21.938,8.506 C21.938,5.416 20.144,2.745 17.542,1.482 C18.568,0.983 19.720,0.704 20.937,0.704 C25.239,0.704 28.726,4.197 28.726,8.506 C28.726,12.815 25.239,16.309 20.937,16.309 ZM2.518,16.309 C1.943,16.309 1.383,16.244 0.843,16.126 C4.340,15.358 6.958,12.239 6.958,8.506 C6.958,4.773 4.340,1.654 0.843,0.887 C1.383,0.768 1.943,0.704 2.518,0.704 C6.820,0.704 10.307,4.197 10.307,8.506 C10.307,12.815 6.820,16.309 2.518,16.309 Z">
              </path>
            </svg>
          </div>
        </div>
      </div>
      <div class="grid-item grid-7 grid-item--height2">
        <div class="cell-border text-center">
          <div class="d-flex h-100 align-items-center">
            <div class="wrapper">
              <h4 class="light-pink inview animated delay-1 slow" data-ease="fadeIn">Only the highest quality items will ever be released to market.</h4>
              <img src="@/assets/home_grid_7.png" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <div class="grid-item grid-8">
        <div class="inner-cell text-center">
          <div class="icon-svg"><svg class="img-fluid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="85px" height="17px" viewBox="0 0 85 17">
              <path fill-rule="evenodd" fill="#ffc75a"
                d="M84.203,16.126 C83.664,16.244 83.104,16.309 82.529,16.309 C78.227,16.309 74.740,12.815 74.740,8.506 C74.740,4.197 78.227,0.704 82.529,0.704 C83.104,0.704 83.664,0.768 84.203,0.887 C80.707,1.654 78.089,4.773 78.089,8.506 C78.089,12.239 80.707,15.358 84.203,16.126 ZM64.110,16.309 C59.808,16.309 56.321,12.815 56.321,8.506 C56.321,4.197 59.808,0.704 64.110,0.704 C65.327,0.704 66.478,0.983 67.504,1.482 C64.903,2.745 63.109,5.416 63.109,8.506 C63.109,11.596 64.903,14.267 67.504,15.531 C66.478,16.029 65.327,16.309 64.110,16.309 ZM42.523,16.309 C38.221,16.309 34.734,12.815 34.734,8.506 C34.734,7.429 34.952,6.403 35.346,5.469 C35.374,5.402 35.406,5.336 35.436,5.270 C35.458,5.221 35.479,5.172 35.502,5.123 C36.760,2.509 39.431,0.704 42.523,0.704 C45.615,0.704 48.286,2.509 49.544,5.123 C49.568,5.172 49.588,5.221 49.611,5.270 C49.641,5.336 49.672,5.402 49.700,5.469 C50.095,6.403 50.313,7.429 50.313,8.506 C50.313,12.815 46.825,16.309 42.523,16.309 ZM20.937,16.309 C19.720,16.309 18.568,16.029 17.542,15.531 C20.144,14.267 21.938,11.596 21.938,8.506 C21.938,5.416 20.144,2.745 17.542,1.482 C18.568,0.983 19.720,0.704 20.937,0.704 C25.239,0.704 28.726,4.197 28.726,8.506 C28.726,12.815 25.239,16.309 20.937,16.309 ZM2.518,16.309 C1.943,16.309 1.383,16.244 0.843,16.126 C4.340,15.358 6.958,12.239 6.958,8.506 C6.958,4.773 4.340,1.654 0.843,0.887 C1.383,0.768 1.943,0.704 2.518,0.704 C6.820,0.704 10.307,4.197 10.307,8.506 C10.307,12.815 6.820,16.309 2.518,16.309 Z">
              </path>
            </svg>
          </div>
          <p class="mb-0 inview animated delay-1">Clementine’s Nightmare is restricting items that will go to the blockchain based on rarity.</p>
          <div class="icon-svg"><svg class="img-fluid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="85px" height="17px" viewBox="0 0 85 17">
              <path fill-rule="evenodd" fill="#ffc75a"
                d="M84.203,16.126 C83.664,16.244 83.104,16.309 82.529,16.309 C78.227,16.309 74.740,12.815 74.740,8.506 C74.740,4.197 78.227,0.704 82.529,0.704 C83.104,0.704 83.664,0.768 84.203,0.887 C80.707,1.654 78.089,4.773 78.089,8.506 C78.089,12.239 80.707,15.358 84.203,16.126 ZM64.110,16.309 C59.808,16.309 56.321,12.815 56.321,8.506 C56.321,4.197 59.808,0.704 64.110,0.704 C65.327,0.704 66.478,0.983 67.504,1.482 C64.903,2.745 63.109,5.416 63.109,8.506 C63.109,11.596 64.903,14.267 67.504,15.531 C66.478,16.029 65.327,16.309 64.110,16.309 ZM42.523,16.309 C38.221,16.309 34.734,12.815 34.734,8.506 C34.734,7.429 34.952,6.403 35.346,5.469 C35.374,5.402 35.406,5.336 35.436,5.270 C35.458,5.221 35.479,5.172 35.502,5.123 C36.760,2.509 39.431,0.704 42.523,0.704 C45.615,0.704 48.286,2.509 49.544,5.123 C49.568,5.172 49.588,5.221 49.611,5.270 C49.641,5.336 49.672,5.402 49.700,5.469 C50.095,6.403 50.313,7.429 50.313,8.506 C50.313,12.815 46.825,16.309 42.523,16.309 ZM20.937,16.309 C19.720,16.309 18.568,16.029 17.542,15.531 C20.144,14.267 21.938,11.596 21.938,8.506 C21.938,5.416 20.144,2.745 17.542,1.482 C18.568,0.983 19.720,0.704 20.937,0.704 C25.239,0.704 28.726,4.197 28.726,8.506 C28.726,12.815 25.239,16.309 20.937,16.309 ZM2.518,16.309 C1.943,16.309 1.383,16.244 0.843,16.126 C4.340,15.358 6.958,12.239 6.958,8.506 C6.958,4.773 4.340,1.654 0.843,0.887 C1.383,0.768 1.943,0.704 2.518,0.704 C6.820,0.704 10.307,4.197 10.307,8.506 C10.307,12.815 6.820,16.309 2.518,16.309 Z">
              </path>
            </svg>
          </div>
        </div>
      </div>
      <div class="grid-item grid-9">
        <div class="cell-border cell-black text-center black">
          <div class="icon-svg"><svg class="img-fluid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="85px" height="17px" viewBox="0 0 85 17">
              <path fill-rule="evenodd" fill="#000"
                d="M84.203,16.126 C83.664,16.244 83.104,16.309 82.529,16.309 C78.227,16.309 74.740,12.815 74.740,8.506 C74.740,4.197 78.227,0.704 82.529,0.704 C83.104,0.704 83.664,0.768 84.203,0.887 C80.707,1.654 78.089,4.773 78.089,8.506 C78.089,12.239 80.707,15.358 84.203,16.126 ZM64.110,16.309 C59.808,16.309 56.321,12.815 56.321,8.506 C56.321,4.197 59.808,0.704 64.110,0.704 C65.327,0.704 66.478,0.983 67.504,1.482 C64.903,2.745 63.109,5.416 63.109,8.506 C63.109,11.596 64.903,14.267 67.504,15.531 C66.478,16.029 65.327,16.309 64.110,16.309 ZM42.523,16.309 C38.221,16.309 34.734,12.815 34.734,8.506 C34.734,7.429 34.952,6.403 35.346,5.469 C35.374,5.402 35.406,5.336 35.436,5.270 C35.458,5.221 35.479,5.172 35.502,5.123 C36.760,2.509 39.431,0.704 42.523,0.704 C45.615,0.704 48.286,2.509 49.544,5.123 C49.568,5.172 49.588,5.221 49.611,5.270 C49.641,5.336 49.672,5.402 49.700,5.469 C50.095,6.403 50.313,7.429 50.313,8.506 C50.313,12.815 46.825,16.309 42.523,16.309 ZM20.937,16.309 C19.720,16.309 18.568,16.029 17.542,15.531 C20.144,14.267 21.938,11.596 21.938,8.506 C21.938,5.416 20.144,2.745 17.542,1.482 C18.568,0.983 19.720,0.704 20.937,0.704 C25.239,0.704 28.726,4.197 28.726,8.506 C28.726,12.815 25.239,16.309 20.937,16.309 ZM2.518,16.309 C1.943,16.309 1.383,16.244 0.843,16.126 C4.340,15.358 6.958,12.239 6.958,8.506 C6.958,4.773 4.340,1.654 0.843,0.887 C1.383,0.768 1.943,0.704 2.518,0.704 C6.820,0.704 10.307,4.197 10.307,8.506 C10.307,12.815 6.820,16.309 2.518,16.309 Z">
              </path>
            </svg>
          </div>
          <div class="d-flex h-100 align-items-center">
            <p class="mb-0 inview animated delay-1">Gain a massive head start in the marketplace with additional long term benefits for early adopters.
            </p>
          </div>
        </div>
      </div>
      <div class="grid-item grid-10 grid-item--width2">
        <div class="d-flex h-100 align-items-center">
          <div class="cell-border-margin">
            <div class="inner text-center">
              <div class="wrapper d-flex h-100 align-items-center">
                <h4 class="mb-0 inview animated delay-1" data-ease="fadeIn">Both Genesis and Eclipse NFT holders have exclusive access to mint Project Neighborhood.</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="neighbordhood">
    <div class="headline container-xl text-center">
      <h2 class="inview animated delay-1">PROJECT NEIGHBORHOOD IS COMING!</h2>
      <div class="draw-line-wrapper inview animated delay-2">
        <div class="draw-line"><span class="title"><img src="@/assets/line-tan.png" class="img-fluid" /></span></div>
      </div>
      <div class="wrapper inview animated delay-2" data-ease="fadeIn">
        <h4>Project Neighborhood will be a mint for both a base in-game skin asset and an individual 3D PFP that will be the first step in providing full Metaverse support.</h4>
      </div>

    </div>
  </section>
</div>
</template>

<script>
import Isotope from 'isotope-layout'
import 'isotope-packery'
import Hero from '@/components/Hero.vue'
import Characters from '@/components/Characters.vue'
import Highlights from '@/components/Highlights.vue'
export default {
  name: 'Home',
  components: {
    Hero,
    Characters,
    Highlights
  },
  mounted() {
    var grid = document.querySelector('.grid');
    new Isotope(grid, {
      itemSelector: '.grid-item',
      layoutMode: 'packery',
      percentPosition: true
    });
  }
};
</script>

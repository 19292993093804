import {
  createRouter,
  createWebHistory
} from "vue-router";
import Home from "@/views/Home.vue";
import PlayToEarn from "@/views/PlayToEarn.vue";
import Nft from "@/views/Nft.vue";
import Journey from "@/views/Journey.vue";
import BigRedButton from "@/views/BigRedButton.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    title: process.env.VUE_APP_SITE_NAME,
    component: Home
  },
  {
    path: "/play-and-earn",
    name: "Play & Earn",
    component: PlayToEarn
  },
  {
    path: "/nft",
    name: "NFT",
    component: Nft
  },
  {
    path: "/the-journey",
    name: "The Journey",
    component: Journey
  },
  {
    path: "/big-red-button",
    name: "Big Red Button Entertainment",
    component: BigRedButton
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth"
      };
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  }
});

router.beforeEach((to, from, next) => {
  var mainTitle = to.title ?
    to.title + " | " + process.env.VUE_APP_SITE_NAME :
    to.name + " | " + process.env.VUE_APP_SITE_NAME;
  document.title = mainTitle;
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
  next();
});

export default router;
<template>
<section id="marquee" class="journey-section">
  <div class="container">
    <div class="d-md-flex">
      <div class="content-block">
        <div class="wrapper">
          <h1 class="title inview animated delay-1">Eclipse: Phase 2 Collection</h1>
          <div class="draw-line-wrapper inview animated delay-2">
            <div class="draw-line"><span class="title"><img src="@/assets/line-tan.png" class="img-fluid" /></span></div>
          </div>
          <div class="sub-wrapper">
            <h3 class="sub-title light-pink mt-6 inview animated delay-3">DREAMWORTHY BENEFITS</h3>
            <div class="inview animated delay-4">
              <p>Eclipse is the second phase in the whimsically macabre Clementine’s Nightmare collection. With twice as many characters as Genesis there’s lots more to explore, and plenty of new reasons to grow your collection. Enjoy all the
                amazing utilities and rewards of being in the Neighborhood.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="content-slider inview animated delay-2" data-ease="fadeInDown">
        <swiper id="parent-swiper" :slides-per-view="3" :options="swiperOption" :breakpoints='{ 300: { slidesPerView: 1,spaceBetween: 8  }, 992: { slidesPerView: 2, spaceBetween: 15 }, 1200: { slidesPerView: 3, spaceBetween: 15 } }'
          @swiper="setSwiper">
          <swiper-slide>
            <div class="slide">
              <div class="card-box">
                <h2 class="title-heading slide-inview animated">PROJECT NEIGHBORHOOD</h2>
                <div class="draw-line"><span class="line-title"><img src="@/assets/line-tan.png" class="img-fluid" /></span></div>
                <p>Holders of Genesis & Eclipse NFTs will automatically join Project Neighborhood–a hyper exclusive 3D collection that is minted as both an in-game skin and a metaverse-ready PFP.</p>
              </div>
              <div class="draw-line-end">
                <div class="draw-line"><span class="line-title"><img src="@/assets/line-tan.png" class="img-fluid" /></span></div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="slide">
              <div class="card-box">
                <h2 class="title-heading slide-inview animated">EARLY PLAY</h2>
                <div class="draw-line"><span class="line-title"><img src="@/assets/line-tan.png" class="img-fluid" /></span></div>
                <p>Participate in the Alpha and Beta game testing and be an integral part of the game development. This is an exciting opportunity to not only be one of the first to play the Clementine’s Nightmare Digital Card Battler, but also to
                  have your voice heard as we perfect this key part of the gameplay experience together. Estimated timeframe for Alpha testing for holders will be EOY 2022 to Q1 of 2023.</p>
              </div>
              <div class="draw-line-end">
                <div class="draw-line"><span class="line-title"><img src="@/assets/line-tan.png" class="img-fluid" /></span></div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="slide">
              <div class="card-box">
                <h2 class="title-heading slide-inview animated">CURRENCY ADVANTAGE</h2>
                <div class="draw-line"><span class="title"><img src="@/assets/line-tan.png" class="img-fluid" /></span></div>
                <p>Your in-game currency, PIXEL, begins accruing the instant you acquire each new NFT. The more NFTs you hold, how long you hold them, and how rare your holdings are, will each contribute to increasing your accumulated PIXEL as more
                  PIXEL drops. You can also convert your PIXEL tokens to supported web3 enabled blockchains and generate INK tokens. (Note: INK tokens cannot be converted back to in-game PIXEL currency once converted.)</p>
              </div>
              <div class="draw-line-end">
                <div class="draw-line"><span class="line-title"><img src="@/assets/line-tan.png" class="img-fluid" /></span></div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="slide">
              <div class="card-box">
                <h2 class="title-heading slide-inview animated">INSIDER ACCESS</h2>
                <div class="draw-line"><span class="title"><img src="@/assets/line-tan.png" class="img-fluid" /></span></div>
                <p>Get access to our community alpha network, offering pre-sale spots for additional projects, regular communications with the Clementine’s Nightmare team, inclusion in private AMA’s, plus attendance to IRL events for
                  Clementine’s Nightmare.</p>
              </div>
              <div class="draw-line-end">
                <div class="draw-line"><span class="line-title"><img src="@/assets/line-tan.png" class="img-fluid" /></span></div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="slide">
              <div class="card-box">
                <h2 class="title-heading slide-inview animated">UNLOCK BONUSES</h2>
                <div class="draw-line"><span class="title"><img src="@/assets/line-tan.png" class="img-fluid" /></span></div>
                <p>Unlock a variety of in-game bonus assets such as unique Clementine skins and badges based on length of hold. Plus look out for more perks that will be made available to NFT holders only.</p>
                <div class="draw-line-end">
                  <div class="draw-line"><span class="line-title"><img src="@/assets/line-tan.png" class="img-fluid" /></span></div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-button-prev" @click="slidePrev"></div>
        <div class="swiper-button-next" @click="slideNext"></div>
      </div>
    </div>
    <div id="cta-btns">
      <a class="btn btn-theme btn-simple" href="https://opensea.io/category/clementines-nightmare" target="_blank" rel="noopener noreferrer"><span class="icon"><img class="img-fluid pt-1 px-1" src="@/assets/calendar_btn.png" /></span><span
          class="text">View the Current Collection</span></a>
    </div>
    <div id="clem-icon"><img class="img-fluid" src="@/assets/roadmap_clem_small.png"></div>
  </div>
</section>
<section id="section-1" class="journey-section">
  <div class="parallax-container">
    <div class="bkg parallax">
      <img src="@/assets/roadmap_section_1_desktop_bkg.jpg" alt="Eclipse: Phase 2 Collection" />
    </div>
  </div>
  <div class="container h-100">
    <div class="container-wrapper d-md-flex align-items-center">
      <div class="wrapper content-block">
        <h3 class="sub-title text-left inview animated white">GENESIS (PHASE 1) <span class="black">vs.</span> ECLIPSE (PHASE 2)</h3>
        <h2 class="title text-left yellow mb-5 inview animated delay-1">TWO COMPELLING OFFERS</h2>
        <div class="row row-eq-height">
          <div class="col-md-6">
            <div class="card inview animated delay-2">
              <div class="card-inner">
                <div class="icon-svg"><svg class="img-fluid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="85px" height="17px" viewBox="0 0 85 17">
                    <path fill-rule="evenodd" fill="#ffc75a"
                      d="M84.203,16.126 C83.664,16.244 83.104,16.309 82.529,16.309 C78.227,16.309 74.740,12.815 74.740,8.506 C74.740,4.197 78.227,0.704 82.529,0.704 C83.104,0.704 83.664,0.768 84.203,0.887 C80.707,1.654 78.089,4.773 78.089,8.506 C78.089,12.239 80.707,15.358 84.203,16.126 ZM64.110,16.309 C59.808,16.309 56.321,12.815 56.321,8.506 C56.321,4.197 59.808,0.704 64.110,0.704 C65.327,0.704 66.478,0.983 67.504,1.482 C64.903,2.745 63.109,5.416 63.109,8.506 C63.109,11.596 64.903,14.267 67.504,15.531 C66.478,16.029 65.327,16.309 64.110,16.309 ZM42.523,16.309 C38.221,16.309 34.734,12.815 34.734,8.506 C34.734,7.429 34.952,6.403 35.346,5.469 C35.374,5.402 35.406,5.336 35.436,5.270 C35.458,5.221 35.479,5.172 35.502,5.123 C36.760,2.509 39.431,0.704 42.523,0.704 C45.615,0.704 48.286,2.509 49.544,5.123 C49.568,5.172 49.588,5.221 49.611,5.270 C49.641,5.336 49.672,5.402 49.700,5.469 C50.095,6.403 50.313,7.429 50.313,8.506 C50.313,12.815 46.825,16.309 42.523,16.309 ZM20.937,16.309 C19.720,16.309 18.568,16.029 17.542,15.531 C20.144,14.267 21.938,11.596 21.938,8.506 C21.938,5.416 20.144,2.745 17.542,1.482 C18.568,0.983 19.720,0.704 20.937,0.704 C25.239,0.704 28.726,4.197 28.726,8.506 C28.726,12.815 25.239,16.309 20.937,16.309 ZM2.518,16.309 C1.943,16.309 1.383,16.244 0.843,16.126 C4.340,15.358 6.958,12.239 6.958,8.506 C6.958,4.773 4.340,1.654 0.843,0.887 C1.383,0.768 1.943,0.704 2.518,0.704 C6.820,0.704 10.307,4.197 10.307,8.506 C10.307,12.815 6.820,16.309 2.518,16.309 Z" />
                  </svg></div>
                <h3 class="sub-title">Genesis:</h3>
                <p>Get 15% additional in-game Ink currency when you own a piece of the original Clementine’s Nightmare Phase 1 collection. Begin accruing Ink at point of purchase. Take advantage of exciting holder benefits including early access to
                  the game as well as to our community alpha network. Plus, claim rewards from the upcoming Project Neighborhood, including free mints* for every NFT you hold. <small>*(plus gas fees)</small></p>
                <div class="icon-svg"><svg class="img-fluid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="85px" height="17px" viewBox="0 0 85 17">
                    <path fill-rule="evenodd" fill="#ffc75a"
                      d="M84.203,16.126 C83.664,16.244 83.104,16.309 82.529,16.309 C78.227,16.309 74.740,12.815 74.740,8.506 C74.740,4.197 78.227,0.704 82.529,0.704 C83.104,0.704 83.664,0.768 84.203,0.887 C80.707,1.654 78.089,4.773 78.089,8.506 C78.089,12.239 80.707,15.358 84.203,16.126 ZM64.110,16.309 C59.808,16.309 56.321,12.815 56.321,8.506 C56.321,4.197 59.808,0.704 64.110,0.704 C65.327,0.704 66.478,0.983 67.504,1.482 C64.903,2.745 63.109,5.416 63.109,8.506 C63.109,11.596 64.903,14.267 67.504,15.531 C66.478,16.029 65.327,16.309 64.110,16.309 ZM42.523,16.309 C38.221,16.309 34.734,12.815 34.734,8.506 C34.734,7.429 34.952,6.403 35.346,5.469 C35.374,5.402 35.406,5.336 35.436,5.270 C35.458,5.221 35.479,5.172 35.502,5.123 C36.760,2.509 39.431,0.704 42.523,0.704 C45.615,0.704 48.286,2.509 49.544,5.123 C49.568,5.172 49.588,5.221 49.611,5.270 C49.641,5.336 49.672,5.402 49.700,5.469 C50.095,6.403 50.313,7.429 50.313,8.506 C50.313,12.815 46.825,16.309 42.523,16.309 ZM20.937,16.309 C19.720,16.309 18.568,16.029 17.542,15.531 C20.144,14.267 21.938,11.596 21.938,8.506 C21.938,5.416 20.144,2.745 17.542,1.482 C18.568,0.983 19.720,0.704 20.937,0.704 C25.239,0.704 28.726,4.197 28.726,8.506 C28.726,12.815 25.239,16.309 20.937,16.309 ZM2.518,16.309 C1.943,16.309 1.383,16.244 0.843,16.126 C4.340,15.358 6.958,12.239 6.958,8.506 C6.958,4.773 4.340,1.654 0.843,0.887 C1.383,0.768 1.943,0.704 2.518,0.704 C6.820,0.704 10.307,4.197 10.307,8.506 C10.307,12.815 6.820,16.309 2.518,16.309 Z" />
                  </svg></div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card inview animated delay-3">
              <div class="card-inner">
                <div class="icon-svg"><svg class="img-fluid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="85px" height="17px" viewBox="0 0 85 17">
                    <path fill-rule="evenodd" fill="#ffc75a"
                      d="M84.203,16.126 C83.664,16.244 83.104,16.309 82.529,16.309 C78.227,16.309 74.740,12.815 74.740,8.506 C74.740,4.197 78.227,0.704 82.529,0.704 C83.104,0.704 83.664,0.768 84.203,0.887 C80.707,1.654 78.089,4.773 78.089,8.506 C78.089,12.239 80.707,15.358 84.203,16.126 ZM64.110,16.309 C59.808,16.309 56.321,12.815 56.321,8.506 C56.321,4.197 59.808,0.704 64.110,0.704 C65.327,0.704 66.478,0.983 67.504,1.482 C64.903,2.745 63.109,5.416 63.109,8.506 C63.109,11.596 64.903,14.267 67.504,15.531 C66.478,16.029 65.327,16.309 64.110,16.309 ZM42.523,16.309 C38.221,16.309 34.734,12.815 34.734,8.506 C34.734,7.429 34.952,6.403 35.346,5.469 C35.374,5.402 35.406,5.336 35.436,5.270 C35.458,5.221 35.479,5.172 35.502,5.123 C36.760,2.509 39.431,0.704 42.523,0.704 C45.615,0.704 48.286,2.509 49.544,5.123 C49.568,5.172 49.588,5.221 49.611,5.270 C49.641,5.336 49.672,5.402 49.700,5.469 C50.095,6.403 50.313,7.429 50.313,8.506 C50.313,12.815 46.825,16.309 42.523,16.309 ZM20.937,16.309 C19.720,16.309 18.568,16.029 17.542,15.531 C20.144,14.267 21.938,11.596 21.938,8.506 C21.938,5.416 20.144,2.745 17.542,1.482 C18.568,0.983 19.720,0.704 20.937,0.704 C25.239,0.704 28.726,4.197 28.726,8.506 C28.726,12.815 25.239,16.309 20.937,16.309 ZM2.518,16.309 C1.943,16.309 1.383,16.244 0.843,16.126 C4.340,15.358 6.958,12.239 6.958,8.506 C6.958,4.773 4.340,1.654 0.843,0.887 C1.383,0.768 1.943,0.704 2.518,0.704 C6.820,0.704 10.307,4.197 10.307,8.506 C10.307,12.815 6.820,16.309 2.518,16.309 Z" />
                  </svg></div>
                <h3 class="sub-title">Eclipse:</h3>
                <p>Go deeper into the Neighborhood with the newest collection from Clementine’s Nightmare, featuring yet-to-be unveiled mysteries, rarities and oddities. Begin accruing Ink at point of purchase. Take advantage of exciting holder
                  benefits including early access to the game as well as to our community alpha network. Plus, claim rewards and gain exclusive access to Project Neighborhood–the upcoming 3D project from Clementine’s Nightmare.</p>
                <div class="icon-svg"><svg class="img-fluid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="85px" height="17px" viewBox="0 0 85 17">
                    <path fill-rule="evenodd" fill="#ffc75a"
                      d="M84.203,16.126 C83.664,16.244 83.104,16.309 82.529,16.309 C78.227,16.309 74.740,12.815 74.740,8.506 C74.740,4.197 78.227,0.704 82.529,0.704 C83.104,0.704 83.664,0.768 84.203,0.887 C80.707,1.654 78.089,4.773 78.089,8.506 C78.089,12.239 80.707,15.358 84.203,16.126 ZM64.110,16.309 C59.808,16.309 56.321,12.815 56.321,8.506 C56.321,4.197 59.808,0.704 64.110,0.704 C65.327,0.704 66.478,0.983 67.504,1.482 C64.903,2.745 63.109,5.416 63.109,8.506 C63.109,11.596 64.903,14.267 67.504,15.531 C66.478,16.029 65.327,16.309 64.110,16.309 ZM42.523,16.309 C38.221,16.309 34.734,12.815 34.734,8.506 C34.734,7.429 34.952,6.403 35.346,5.469 C35.374,5.402 35.406,5.336 35.436,5.270 C35.458,5.221 35.479,5.172 35.502,5.123 C36.760,2.509 39.431,0.704 42.523,0.704 C45.615,0.704 48.286,2.509 49.544,5.123 C49.568,5.172 49.588,5.221 49.611,5.270 C49.641,5.336 49.672,5.402 49.700,5.469 C50.095,6.403 50.313,7.429 50.313,8.506 C50.313,12.815 46.825,16.309 42.523,16.309 ZM20.937,16.309 C19.720,16.309 18.568,16.029 17.542,15.531 C20.144,14.267 21.938,11.596 21.938,8.506 C21.938,5.416 20.144,2.745 17.542,1.482 C18.568,0.983 19.720,0.704 20.937,0.704 C25.239,0.704 28.726,4.197 28.726,8.506 C28.726,12.815 25.239,16.309 20.937,16.309 ZM2.518,16.309 C1.943,16.309 1.383,16.244 0.843,16.126 C4.340,15.358 6.958,12.239 6.958,8.506 C6.958,4.773 4.340,1.654 0.843,0.887 C1.383,0.768 1.943,0.704 2.518,0.704 C6.820,0.704 10.307,4.197 10.307,8.506 C10.307,12.815 6.820,16.309 2.518,16.309 Z" />
                  </svg></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section id="section-2" class="journey-section">
  <div class="container content-block">
    <h3 class="sub-title inview animated">GENESIS: PHASE 1 COLLECTION</h3>
    <h2 class="title inview animated delay-1">HOLDER BENEFITS</h2>
    <p class="content inview animated delay-2">Hurry, there is still some Genesis collection up for grabs! Get a 15% bonus of in-game PIXEL currency when the Ink drops. This is your chance to own a piece of
      Clementine’s Nightmare history through key characters who are core to the story, spanning 5,000 NFTs, with 10 characters and 500 variants of each. Holders accumulate in game currency, PIXEL. Also includes the Master Key Pass granting early
      access to the game.</p>
  </div>
  <div class="container content-lower">
    <div class="inner-block">
      <div class="inner">
        <div class="row">
          <div class="col-md-5">
            <div class="draw-line-wrapper inview animated">
              <div class="draw-line"><span class="title"><img src="@/assets/line-tan.png" class="img-fluid" /></span></div>
            </div>
            <h3 class="sub-title inview animated delay-1">Holding a Genesis NFT grants an additional <span class="yellow">15% bonus</span> to the final multiplier when the INK token launches:</h3>
            <ul class="yellow mt-3 inview animated delay-2">
              <li>Ultra Legendary 5x</li>
              <li>Legendary 4.5x</li>
              <li>Rare 3.5x ( Top 500 Rank After Legendaries as determined by Rarity Sniper)</li>
              <li>Common 3x ( Everything Else)</li>
            </ul>
            <div class="draw-line-wrapper inview animated delay-3">
              <div class="draw-line"><span class="title"><img src="@/assets/line-tan.png" class="img-fluid" /></span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section id="section-3" class="journey-section">
  <div id="fern-icon"><img src="@/assets/roadmap_about_leaf.png" class="img-fluid" /></div>
  <div class="about-bkg">
    <div class="container content-block">
      <div class="icon-svg"><svg class="img-fluid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="85px" height="17px" viewBox="0 0 85 17">
          <path fill-rule="evenodd" fill="rgb(196, 149, 205)"
            d="M84.203,16.126 C83.664,16.244 83.104,16.309 82.529,16.309 C78.227,16.309 74.740,12.815 74.740,8.506 C74.740,4.197 78.227,0.704 82.529,0.704 C83.104,0.704 83.664,0.768 84.203,0.887 C80.707,1.654 78.089,4.773 78.089,8.506 C78.089,12.239 80.707,15.358 84.203,16.126 ZM64.110,16.309 C59.808,16.309 56.321,12.815 56.321,8.506 C56.321,4.197 59.808,0.704 64.110,0.704 C65.327,0.704 66.478,0.983 67.504,1.482 C64.903,2.745 63.109,5.416 63.109,8.506 C63.109,11.596 64.903,14.267 67.504,15.531 C66.478,16.029 65.327,16.309 64.110,16.309 ZM42.523,16.309 C38.221,16.309 34.734,12.815 34.734,8.506 C34.734,7.429 34.952,6.403 35.346,5.469 C35.374,5.402 35.406,5.336 35.436,5.270 C35.458,5.221 35.479,5.172 35.502,5.123 C36.760,2.509 39.431,0.704 42.523,0.704 C45.615,0.704 48.286,2.509 49.544,5.123 C49.568,5.172 49.588,5.221 49.611,5.270 C49.641,5.336 49.672,5.402 49.700,5.469 C50.095,6.403 50.313,7.429 50.313,8.506 C50.313,12.815 46.825,16.309 42.523,16.309 ZM20.937,16.309 C19.720,16.309 18.568,16.029 17.542,15.531 C20.144,14.267 21.938,11.596 21.938,8.506 C21.938,5.416 20.144,2.745 17.542,1.482 C18.568,0.983 19.720,0.704 20.937,0.704 C25.239,0.704 28.726,4.197 28.726,8.506 C28.726,12.815 25.239,16.309 20.937,16.309 ZM2.518,16.309 C1.943,16.309 1.383,16.244 0.843,16.126 C4.340,15.358 6.958,12.239 6.958,8.506 C6.958,4.773 4.340,1.654 0.843,0.887 C1.383,0.768 1.943,0.704 2.518,0.704 C6.820,0.704 10.307,4.197 10.307,8.506 C10.307,12.815 6.820,16.309 2.518,16.309 Z" />
        </svg></div>
      <h2 class="title inview animated">About</h2>
      <h3 class="sub-title inview animated delay-1">ABOUT PROJECT COLLECTION</h3>
      <p class="content inview animated delay-2">Project Neighborhood is a separate 3D NTF created as an exclusive reward for our NTF holders. These will be from the world of Clementine but differ greatly from the original Clementine’s
        Nightmare collection. Project Neighborhood will be a mint for both a base, in-game skin asset and an individual 3D PFP that will be the first step in providing full metaverse support.</p>
    </div>
    <section id="journey" class="inview animated">
      <div class="title-wrapper inview animated delay-1">
        <div class="d-flex justify-content-start align-items-center">
          <img src="@/assets/road_icon.png" class="img-fluid icon" alt="Roadmap">
          <h2 class="yellow ml-3">Roadmap</h2>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="item">
            <div class="wrapper">
              <img class="img-fluid radius" src="@/assets/road/road_1.jpg" alt="NFT Presale: Hall Pass Event">
              <div class="overlay">
                <div class="block">
                  <h4>NFT Presale: Hall Pass Event</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="wrapper">
              <img class="img-fluid radius" src="@/assets/road/road_2.jpg" alt="NFT Launch (Phase 1)">
              <div class="overlay bottom">
                <div class="block">
                  <h4>NFT Launch <span class="tan">(Phase 1)</span></h4>
                </div>
              </div>
            </div>
          </div>
          <div class="item double">
            <div class="wrapper">
              <img class="img-fluid radius" src="@/assets/road/road_4.jpg" alt="NFT Launch (Phase 2)">
              <div class="overlay top">
                <div class="block">
                  <h4>NFT Launch <span class="tan">(Phase 2)</span></h4>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="wrapper">
              <img class="img-fluid radius" src="@/assets/road/road_6.jpg" alt="Story Release (Continuous untilGame Launch)">
              <div class="overlay">
                <div class="block">
                  <h4>Story Release <span class="tan">(Continuous until Game Launch)</span></h4>
                </div>
              </div>
            </div>
          </div>
          <div class="item double">
            <div class="wrapper">
              <img class="img-fluid radius" src="@/assets/road/road_5.jpg" alt="PHASE 3 Genesis Holders (Project Neighborhood NFT mint)">
              <div class="overlay right">
                <div class="block">
                  <h4>Project Neighborhood<span class="tan">NFT free mint*. </span></h4>
                  <small>*(plus gas fees)</small>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="wrapper">
              <img class="img-fluid radius" src="@/assets/road/road_7.jpg" alt="PC Game Alpha (Private invite for holders only)">
              <div class="overlay">
                <div class="block">
                  <h4>PC Game Alpha <span class="tan">(Private invite for holders only)</span></h4>
                </div>
              </div>
            </div>
          </div>
          <div class="item double">
            <div class="wrapper">
              <img class="img-fluid radius" src="@/assets/road/road_5_1.jpg" alt="PC Game BETA (OPEN invite for holders only)">
              <div class="overlay right">
                <div class="block">
                  <h4>PC Game BETA <span class="tan">(OPEN invite for holders only)</span></h4>
                </div>
              </div>
            </div>
          </div>
          <div class="item double">
            <div class="wrapper">
              <img class="img-fluid radius" src="@/assets/road/road_9.jpg" alt="Ink Token Launch (Fair Launch)">
              <div class="overlay right top">
                <div class="block">
                  <h4>Ink Token Launch <span class="tan">(Fair Launch)<br>Tokenomics
                      Contract Verified (Audit)
                      Whitepaper
                      Holder Airdrop</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="wrapper">
              <img class="img-fluid radius" src="@/assets/road/road_10.jpg" alt="PnE Mechanics Launched">
              <div class="overlay">
                <div class="block">
                  <h4>P&E Mechanics Launched</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="wrapper">
              <img class="img-fluid radius" src="@/assets/road/road_11.jpg" alt="PC Playable Game Launched">
              <div class="overlay">
                <div class="block">
                  <h4>PC Playable Game Launched</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="wrapper">
              <img class="img-fluid radius" src="@/assets/road/road_12.jpg" alt="Mobile Game Beta (Open invite for holders only)">
              <div class="overlay">
                <div class="block">
                  <h4>Mobile Game Beta <span class="tan">(Open invite for holders only)</span></h4>
                </div>
              </div>
            </div>
          </div>
          <div class="item double">
            <div class="wrapper">
              <img class="img-fluid radius" src="@/assets/road/road_13.jpg" alt="Mobile Game Launch">
              <div class="overlay">
                <div class="block">
                  <h4>Mobile Game Launch</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</section>
</template>

<script>
import helpers from '@/scripts/helpers.js'
import '@/scripts/parallax.js'
import SwiperCore, {
  Navigation
} from 'swiper';
import {
  Swiper,
  SwiperSlide
} from 'swiper/vue';
SwiperCore.use([Navigation]);
export default {
  name: 'Journey',
  components: {
    Swiper,
    SwiperSlide,
  },
  data: () => {
    return {
      mainSwiper: null,
      swiperOption: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  mounted() {
    helpers.initWaypoint();
    // Enable parallax
    if (window.$(".parallax").length) {
      window.$(".parallax").parallax();
    }
    document.body.classList.add('page-journey')
  },
  methods: {
    convertDate(date) {
      let event = new Date(date).toISOString();
      event = event.split("-");
      event = event.join("");
      return event;
    },
    triggerCalendar(e) {
      e.preventDefault();
      var now = new Date();
      var icsMSG = "BEGIN:VCALENDAR\nVERSION:2.0\nPRODID:-//Our Company//NONSGML v1.0//EN\nBEGIN:VEVENT\nUID:event-" + now.getTime() + "@clementinesnightmare.io\nDTSTART:" + this.convertDate("April 7, 2022 17:00:00") +
        "\nSUMMARY:ECLIPSE: PHASE 2 COLLECTION\nEND:VEVENT\nEND:VCALENDAR";
      window.open("data:text/calendar;charset=utf8," + escape(icsMSG));
      return false;
    },
    setSwiper(swiper) {
      this.mainSwiper = swiper;
    },
    slidePrev() {
      this.mainSwiper.slidePrev();
    },
    slideNext() {
      this.mainSwiper.slideNext();
    }
  }
}
</script>

<template>
<main>
  <Menu />
  <router-view />
  <Community />
  <Audio />
</main>
</template>

<script>
import "@/styles/App.scss";
import Menu from '@/components/Menu.vue'
import Community from '@/components/Community.vue'
import Audio from '@/components/Audio.vue'

export default {
  name: 'App',
  components: {
    Menu,
    Audio,
    Community
  }
}
</script>

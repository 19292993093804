<template>
<div id="community">
  <div class="d-md-flex justify-content-between">
    <div class="company d-none d-md-block">
      <div class="inner"><a href="http://brbent.com/" title="Big Red Button Entertainment" target="_blank" rel="noopener noreferrer"><img class="img-fluid" src="@/assets/bigredbutton.png" alt="Big Red Button Entertainment" /></a></div>
    </div>
    <div class="wrapper">
      <h3 class="tan">JOIN OUR COMMUNITY</h3>
      <div class="mx-auto mt-3">
        <Social />
      </div>
    </div>
    <div class="company d-md-none">
      <div class="inner"><a href="http://brbent.com/" title="Big Red Button Entertainment" target="_blank" rel="noopener noreferrer"><img class="img-fluid" src="@/assets/bigredbutton.png" alt="Big Red Button Entertainment" /></a></div>
    </div>
  </div>
  <div class="legal">Copyright &copy; 2021-2022. All Rights Reserved By Pixelated Ink.</div>
</div>
</template>

<script>
import Social from '@/components/Social.vue'
export default {
  name: 'Community',
  components: {
    Social
  }
}
</script>

<style lang="scss" scoped>
$mobile: 767px;
#community {
    padding: 2rem 3rem 1rem;
    background-color: #1e1e1e;
    position: relative;
    margin: 0;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    @media(max-width: $mobile) {
        margin: 0;
    }
    h3 {
        font-size: 2rem;
    }
    .company {
        .inner {
            max-width: 14rem;
            margin: 2rem auto;
            padding: 0 1rem;
            @media(max-width: $mobile) {
                max-width: 38vw;
            }
        }
    }
    .legal {
        padding-top: 3rem;
        font-size: 0.8rem;
        @media(max-width: $mobile) {
            padding-top: 2rem;
        }
    }
}
</style>

<template>
<div class="social-icons">
  <a class="link" href="https://twitter.com/ClementinesGame" title="Twitter" target="_blank" rel="noopener noreferrer"><img class="img-fluid pt-1 px-1" src="@/assets/twitter.svg" alt="Twitter" /></a>
  <a class="link" href="https://www.instagram.com/clementinesgame/" title="Instagram" target="_blank" rel="noopener noreferrer"><img class="img-fluid pt-1 px-1" src="@/assets/instagram.svg" alt="Instagram" /></a>
  <a class="link tooltip-trigger" href="#" title="OpenSea" @click="tooltips">
    <img class="img-fluid pt-1 px-1" src="@/assets/opensea.png" alt="OpenSea" />
    <div class="tooltips collapse fade">
      <a class="outbound mb-1" href="https://opensea.io/collection/official-clementines-nightmare" target="_blank" rel="noopener noreferrer">Opensea - Genesis</a>
      <a class="outbound" href="https://opensea.io/collection/clementines-nightmare-eclipse" target="_blank" rel="noopener noreferrer">Opensea - Eclipse</a>
    </div>
  </a>
  <a class="link tooltip-trigger" href="#" title="Rarity Sniper" @click="tooltips">
    <img class="img-fluid pt-1 px-1" src="@/assets/raritysniper.png" alt="Rarity Sniper" />
    <div class="tooltips collapse fade">
      <a class="outbound mb-1" href="https://raritysniper.com/clementine-s-nightmare" target="_blank" rel="noopener noreferrer">Rarity Sniper V1</a>
      <a class="outbound" href="https://raritysniper.com/clementine-s-nightmare-v2" target="_blank" rel="noopener noreferrer">Rarity Sniper V2</a>
    </div>
  </a>
  <a class="link" href="https://discord.gg/clementinesnightmare" title="Discord" target="_blank" rel="noopener noreferrer"><img class="img-fluid pt-1 px-1" src="@/assets/discord.svg" alt="Discord" /></a>
  <a class="link" href="https://www.youtube.com/c/ClementinesNightmare" title="Youtube" target="_blank" rel="noopener noreferrer"><img class="img-fluid pt-1 px-1" src="@/assets/youtube.png" alt="Youtube" /></a>
  <a class="link email" href="mailto:info@PixelatedInk.io" title="Email" target="_blank" rel="noopener noreferrer"><img class="img-fluid" src="@/assets/email.svg" alt="Email" /></a>
</div>
</template>
<script>
export default {
  methods: {
    tooltips: (e) => {
      var $element = window.$(e.target);
      if (!$element.hasClass('outbound')) {
        e.preventDefault();
        window.$('.tooltips').removeClass('show');
        if ($element.hasClass('img-fluid')) {
          $element.parent().find('.tooltips').addClass('show');
        } else {
          $element.find('.tooltips').addClass('show');
        }
        return false;
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.tooltip-trigger {
    position: relative;
    .tooltips {
        position: absolute;
        inset: 0 auto auto 0;
        margin: 40px 0 0;
        width: 130px;
        background: #333333;
        padding: 10px;
        border-radius: 4px;
        color: #fff;
        font-size: 12px;
        text-align: left;
        a {
            display: block;
        }
    }
}
.social-icons {
    margin: auto;
    text-align: center;
    a.link {
        border-radius: 50%;
        background-color: #fff;
        display: inline-block;
        height: 2.4rem;
        width: 2.4rem;
        padding: 0.3rem;
        text-align: center;
        color: #000;
        margin: 0 0.6rem;
        transition: background 0.3s ease;
        &.email {
            position: relative;
            top: 0;
        }
        &:hover {
            background-color: #e6be33;
        }
        @media(max-width: 767px) {
            height: 3rem;
            width: 3rem;
            margin: 0 0.6rem 0.6rem;
        }
    }
}
</style>

<template>
<div id="characters-section">
  <swiper id="parent-swiper" @swiper="setMainSwiper" :thumbs="{ swiper: thumbsSwiper }" @slideChangeTransitionStart="slideChangeTransitionStart" @slideChangeTransitionEnd="slideChangeTransitionEnd">
    <swiper-slide v-for="character in characters" :key="character.id">
      <div class="slide">
        <picture>
          <source :srcset="require(`@/assets/characters/${character.image}`)" media="(min-width: 768px)">
          <img :src="require(`@/assets/characters/${character.mobile}`)" class="img-fluid w-100" :alt="`${character.name}`">
        </picture>
        <div class="overlay" :class="(character.position=='right')?'right':''">
          <img src="@/assets/character_icon.png" class="img-fluid icon slide-inview animated" :alt="`${character.name}`">
          <h2 class="tan slide-inview animated">{{character.name}}</h2>
          <h3 class="slide-inview animated delay-1">{{character.subtitle}}<br><strong>{{character.faction}}</strong></h3>
          <div class="detail slide-inview animated delay-1 slow">
            <p>{{character.detail}}</p>
          </div>
        </div>
      </div>
    </swiper-slide>
  </swiper>
  <div class="thumbs-section">
    <swiper :slidesPerView="4" :watchSlidesProgress="true" :options="swiperOption" :breakpoints='{
  "300": {
    "slidesPerView": 2.5,
    "spaceBetween": 10
  },
  "768": {
    "slidesPerView": 3.5,
    "spaceBetween": 10
  },
  "992": {
    "slidesPerView": 4,
    "spaceBetween": 30
  },
  "1399": {
    "slidesPerView": 4,
    "spaceBetween": 40
  }
}' @swiper="setThumbsSwiper">

      <swiper-slide @click="toggleSlide(character.id)" v-for="character in characters" :key="character.id">
        <div class="slide zoom">
          <img src="@/assets/thumb_character.png" class="img-fluid radius" :alt="`${character.name}`">
          <div class="overlay-thumb">
            <h3>{{character.name}}</h3>
            <div class="img-block radius"><img :src="require(`@/assets/characters/${character.thumb}`)" class="img-fluid zoom-image" :alt="`${character.name}`"></div>
          </div>
        </div>
      </swiper-slide>

    </swiper>
    <div class="swiper-button-prev d-none d-lg-block" @click="slidePrev"></div>
    <div class="swiper-button-next d-none d-lg-block" @click="slideNext"></div>
  </div>
</div>
</template>

<script>
import characters from '@/json/characters.json'
import SwiperCore, {
  Thumbs,
  Navigation
} from 'swiper';
import {
  Swiper,
  SwiperSlide
} from 'swiper/vue';
SwiperCore.use([Navigation, Thumbs]);
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data: function() {
    return {
      characters: characters,
      mainSwiper: null,
      thumbsSwiper: null,
      swiperOption: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  setup() {
    const slideChangeTransitionStart = () => {
      window.$('.slide-inview').removeClass('fadeInUp');
    };
    const slideChangeTransitionEnd = () => {
      window.$('#parent-swiper .swiper-slide-active .slide-inview').addClass('fadeInUp');
    };
    return {
      slideChangeTransitionStart,
      slideChangeTransitionEnd,
    };
  },
  methods: {
    setMainSwiper(swiper) {
      this.mainSwiper = swiper;
    },
    setThumbsSwiper(swiper) {
      this.thumbsSwiper = swiper;
      window.$('#parent-swiper .swiper-slide-active .slide-inview').addClass('fadeInUp');
    },
    slidePrev() {
      this.thumbsSwiper.slidePrev();
    },
    slideNext() {
      this.thumbsSwiper.slideNext();
    },
    toggleSlide: function(index) {
      this.mainSwiper.slideTo(index);

    },
  }
};
</script>
<style lang="scss" scoped>
$phone: 480px;
$mobile: 767px;
$tablet: 991px;
$laptop: 1199px;
$fullsize: 1799px;
#characters-section {
    .overlay {
        position: absolute;
        top: 26%;
        left: 0;
        color: #fff;
        width: 100%;
        padding: 1.5rem 1.5rem 1rem 8rem;
        max-width: 46rem;
        background-color: rgba(0,0,0,0.25);
        @media(max-width: $tablet) {
            padding-left: 7rem;
            max-width: 38rem;
        }
        @media(max-width: $mobile) {
            bottom: 0;
            top: inherit;
            padding: 1.5rem 2rem;
            max-width: inherit;
        }
        &.right {
            left: inherit !important;
            right: 0;
            padding-left: 7rem;
            padding-right: 3rem;
            @media(max-width: $tablet) {
                padding-left: 6rem;
            }
            @media(max-width: $mobile) {
                left: 0 !important;
                right: inherit;
                padding: 1.5rem 2rem;
            }
            .icon {
                left: 1rem;
            }
        }
        .slide-inview {
            opacity: 0;
            &.fadeInUp {
                opacity: 1;
            }
        }
        .icon {
            position: absolute;
            left: 2rem;
            top: 1.2rem;
            max-width: 5rem;
            @media(max-width: $tablet) {
                max-width: 4rem;
            }
            @media(max-width: $mobile) {
                display: none;
            }
        }
        h2 {
            max-width: 40rem;
            font-weight: 300;
            @media(max-width: $tablet) {
                max-width: 30rem;
                font-size: 3.5rem;
            }
            @media(max-width: $mobile) {
                max-width: 20rem;
            }
        }
        h3 {
            font-size: 1.8rem;
            font-weight: 300;
            line-height: 1;
            @media(max-width: $laptop) {
                font-size: 1.7rem;
            }
            strong {
                color: #edcc73;
            }
        }
    }
    .thumbs-section {
        position: relative;
        margin: -12rem auto auto;
        padding: 0 5rem 4rem;
        max-width: 1799px;
        @media(min-width: 2000px) {
            margin-top: -14vw;
        }
        @media(max-width: $fullsize) {
            margin-top: -8rem;
        }
        @media(max-width: $tablet) {
            padding: 0;
            margin-top: 2rem;
        }
        .slide {
            max-width: 355px;
            .overlay-thumb {
                position: absolute;
                top: 18%;
                left: 0;
                right: 0;
                text-align: center;
                color: #ffffff;
                max-width: 355px;
                padding: 0 2rem;
                h3 {
                    font-size: 2rem;
                    margin-bottom: 1rem;
                    transition: color 0.3s ease;
                    @media(max-width: $tablet) {
                        font-size: 1.9rem;
                    }
                    @media(max-width: $mobile) {
                        font-size: 1.6rem;
                    }
                    @media(max-width: $phone) {
                        font-size: 1.3rem;
                    }
                }
                .img-block {
                    overflow: hidden;
                }
            }
            &:hover {
                h3 {
                    color: #edcc73;
                }
            }
        }
        .swiper-button-next,
        .swiper-button-prev {
            color: #fff;
            font-weight: 600;
        }
        .swiper-button-next {
            right: 15px;
        }
        .swiper-button-prev {
            left: 15px;
        }
    }
}
</style>

<template>
<section class="hero-block">
  <Hero class="vh-110" title="Play & Earn" titleClass="teal" bkgImage="playearn_bkg.jpg" bkgImageMobile="mobile_clementine_bkg.jpg" iconImage="play_earn_icon.png" bkgColor="#09173a" positionClass="bottom" offset="100%">
    <div class="draw-line"><span class="title tan">Pre-Game Launch</span></div>
    <div class="content-block">
      <p>Although there will not be a P&E mechanic available prior to the public game launch, holders will be rewarded. Holders will also be rewarded with early access to the alpha and
        beta phases of the game. The drops will consist of:</p>
      <ul class="box-wrapper">
        <li>
          <p>Multiple <span class="teal">REWARDS</span> and <span class="teal">COSMETICS</span> tied to each <span class="teal">NFT</span></p>
        </li>
        <li>
          <p><span class="teal">REWARDS</span> of <span class="teal">PIXEL</span> tokens during the game beta, just before the official game launch.</p>
        </li>
      </ul>
    </div>
    <div class="draw-line mt-0"><span class="title"><img src="@/assets/line-tan.png" class="img-fluid" /></span></div>
  </Hero>
</section>
<section class="hero-block border-top">
  <Hero class="vh-110 long" bkgImage="playearn_clem_bkg.jpg" bkgImageMobile="mobile_hero_bkg.jpg" positionClass="justify-content-end" bkgColor="#19111e" offset="140%">
    <div class="draw-line"><span class="title tan">Post-Game Launch</span></div>
    <div class="content-block">
      <p>Community members will play games to unlock <span class="light-pink">“PIXEL”</span> tokens. These may be used across multiple aspects of the game, and the greater Clementine’s Nightmare metaverse. The tokens serve as <span
          class="light-pink">CURRENCY</span> for purchasing additional game packs and in-game services. PIXEL tokens can also purchase unleashing transfers of any owned hero. This puts any champion they choose onto the blockchain as an NFT.</p>
      <p>Please note that there will be requirements for creating NFTs from in-game heroes, such as player level and power. <span class="light-pink">Game NFTs</span> will also differ from the <span class="light-pink">Pre-Launch NFTs.</span></p>
      <p>Once the first and only rewards of PIXEL tokens has occurred, no additional PIXEL tokens will be released by the team, and new PIXEL tokens can only be generated by playing the game. Further token details are coming soon.</p>
    </div>
    <div class="draw-line mt-0"><span class="title"><img src="@/assets/line-tan.png" class="img-fluid" /></span></div>
  </Hero>
</section>
</template>

<script>
import Hero from '@/components/Hero.vue'
export default {
  name: 'Play',
  components: {
    Hero
  }
}
</script>

<template>
<div id="expanded-menu" :class="{show: show}">
  <div class="menu-block vh-100 d-flex align-items-center justify-content-center container-xl">
    <div class="wrapper">
      <ul class="navbar-nav text-center">
        <li class="nav-item mx-0 mx-lg-1">
          <router-link class="nav-link py-2 mx-3" to="/" @click="toggleNav">Home</router-link>
        </li>
        <li class="nav-item mx-0 mx-lg-1">
          <router-link class="nav-link py-2 mx-3" to="/play-and-earn" @click="toggleNav">Play & Earn</router-link>
        </li>
        <li class="nav-item mx-0 mx-lg-1">
          <router-link class="nav-link py-2 mx-3" to="/nft" @click="toggleNav">NFT</router-link>
        </li>
        <li class="nav-item mx-0 mx-lg-1">
          <router-link class="nav-link py-2 mx-3" to="/the-journey" @click="toggleNav">The Journey</router-link>
        </li>
        <li class="nav-item mx-0 mx-lg-1">
          <router-link class="nav-link py-2 mx-3" to="/big-red-button" @click="toggleNav">Dev Team</router-link>
        </li>
        <li class="nav-item mx-0 mx-lg-1">
          <a class="nav-link py-2 mx-3" @click="toggleNav" href="https://whitepaper.clementinesnightmare.io/" target="_blank" rel="noopener noreferrer">Whitepaper</a>
        </li>
        <li class="nav-item mx-0 mx-lg-1">
          <a href="https://dashboard.clementinesnightmare.io/" class="nav-link py-2 mx-3" target="_blank" rel="noopener noreferrer">Dashboard</a>
          <a href="https://opensea.io/collection/official-clementines-nightmare" class="nav-link py-2 mx-3" target="_blank" rel="noopener noreferrer" @click="gtag_report_conversion()">Opensea - Genesis</a>
          <a href="https://opensea.io/collection/clementines-nightmare-eclipse" class="nav-link py-2 mx-3" target="_blank" rel="noopener noreferrer" @click="gtag_report_conversion()">Opensea - Eclipse</a>
          <a class="nav-link py-2 mx-3 muted">Unleash</a>
        </li>
      </ul>
      <div class="mt-4">
        <Social />
      </div>
    </div>
  </div>
</div>
<div id="nav">
  <nav class="navbar navbar-expand-lg text-uppercase fixed-top" id="mainNav">
    <div class="container-fluid">
      <a class="navbar-brand" href="/"><img class="img-fluid" alt="Clementine's Nightmare" src="@/assets/brand_logo.png"></a>
      <div class="wrapper d-flex justify-content-end">
        <button class="navbar-toggler" :class="{collapsed: show}" type="button" @click="toggleNav" aria-label="Toggle navigation">
          <div class="hamburger-wrapper"><span></span><span></span><span></span><span></span></div>
        </button>
      </div>
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item mx-0 mx-lg-1">
            <router-link class="nav-link py-1 mx-3" to="/">Home</router-link>
          </li>
          <li class="nav-item mx-0 mx-lg-1">
            <router-link class="nav-link py-1 mx-3" to="/play-and-earn">Play & Earn</router-link>
          </li>
          <li class="nav-item mx-0 mx-lg-1">
            <router-link class="nav-link py-1 mx-3" to="/nft">NFT</router-link>
          </li>
          <li class="nav-item mx-0 mx-lg-1">
            <router-link class="nav-link py-1 mx-3" to="/the-journey">The Journey</router-link>
          </li>
          <li class="nav-item mx-0 mx-lg-1">
            <router-link class="nav-link py-1 mx-3" to="/big-red-button">Dev Team</router-link>
          </li>
          <li class="nav-item mx-0 mx-lg-1">
            <a class="nav-link py-1 mx-3" href="https://whitepaper.clementinesnightmare.io/" target="_blank" rel="noopener noreferrer">Whitepaper</a>
          </li>
        </ul>
      </div>
      <div class="wallet-link d-none d-lg-block">
        <a href="#" class="btn btn-theme btn-black menu-parent" :class={active:isOpen} @click="triggerMenu()">MENU
          <svg class="svg-icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path d="M254.212 957.548l380.833-446.416L254.417 69.21 331.952 2.4l437.836 508.374L332.054 1024z" fill="#fff" />
          </svg></a>
        <div id="menuSub" class="collapse fade">
          <a href="https://dashboard.clementinesnightmare.io/" class="link" target="_blank" rel="noopener noreferrer">DASHBOARD</a>
          <a href="https://opensea.io/collection/official-clementines-nightmare" class="link" target="_blank" rel="noopener noreferrer" @click="gtag_report_conversion()">Opensea - Genesis</a>
          <a href="https://opensea.io/collection/clementines-nightmare-eclipse" class="link" target="_blank" rel="noopener noreferrer" @click="gtag_report_conversion()">Opensea - Eclipse</a>
          <a class="link muted">Unleash</a>
        </div>
      </div>
    </div>
  </nav>
</div>
</template>

<script>
import Cookies from 'universal-cookie';
import Social from '@/components/Social.vue';
const cookies = new Cookies();
export default {
  components: {
    Social
  },
  mounted() {
    const location = window.location.toString();
    if (location.match(/utm_source/)) {
      cookies.set('yt_cookie', location);
    }
    window.scrollTo(0, 0);
    this.$nextTick(function() {
      window.addEventListener("scroll", function() {
        var navbar = document.getElementById("mainNav");
        var nav_classes = navbar.classList;
        if (document.documentElement.scrollTop >= 80) {
          if (nav_classes.contains("navbar-shrink") === false) {
            nav_classes.toggle("navbar-shrink");
          }
        } else {
          if (nav_classes.contains("navbar-shrink") === true) {
            nav_classes.toggle("navbar-shrink");
          }
        }
      })
    })
  },
  data() {
    return {
      show: false,
      isOpen: false
    }
  },
  methods: {
    triggerMenu: function() {
      var subMenu = document.getElementById("menuSub");
      subMenu.classList.toggle("show");
      this.isOpen = !this.isOpen;
      return false;
    },
    toggleNav: function() {
      this.show = !this.show;
    },
    gtag_report_conversion() {
      if (cookies.get('yt_cookie')) {
        var callback = function() {
          window.location = cookies.get('yt_cookie');
        };
        window.gtag('event', 'conversion', {
          'send_to': 'AW-10873079367/4FtNCOLPiKwDEMeM2MAo',
          'event_callback': callback
        });
      }
      return false;
    },
  }
}
</script>

<style lang="scss" scoped>
$laptop: 1199px;
$tablet: 991px;
$phone: 420px;
nav.navbar {
    .navbar-brand {
        max-width: 140px;
        min-width: 100px;
        width: 11vw;
        padding-top: 0.8rem;
    }
    &.navbar-shrink {
        background-color: rgba(0,0,0,0.7);
        .navbar-brand {
            max-width: 100px;
            min-width: 100px;
            padding-top: 0.325rem;
        }
        a.nav-link {
            font-size: 1.5rem;
        }
    }
}
.navbar-nav {
    .nav-item {
        a.nav-link {
            position: relative;
            color: #fff;
            font-size: 1.5rem;
            font-family: 'Londrina Solid', cursive;
            @media(max-width: $laptop) {
                font-size: 1.4rem;
                padding: 0;
            }
            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 0;
                height: 2px;
                background: #ffc75a;
                transition: width 0.3s ease-in-out;
            }

            &.router-link-active,
            &:hover {
                color: #ffc75a;

                &:before {
                    width: 100%;
                }
            }
            &.muted {
                color: #565656;
                cursor: default;
                &:hover {
                    color: #565656;
                    &:before {
                        width: 0;
                    }
                }
            }
        }
    }

}
.wallet-link {
    position: relative;
    &.mobile {
        margin-right: 2rem;
        @media(max-width: $phone) {
            margin-right: 1rem;
        }
    }
    a.menu-parent {
        position: relative;
        z-index: 1;
        padding: 10px;
        background-position: 50%;
        width: 180px;
        height: 47px;
        margin-top: -5px;
        font-size: 20px;
        line-height: inherit;
        @media(max-width: $phone) {
            margin-top: -3px;
        }
        &.active {
            svg {
                transform: rotate(90deg);
            }
        }
        svg {
            transition: transform 0.3s ease;
            width: 14px;
            vertical-align: baseline;
        }
    }
    #menuSub {
        position: absolute;
        top: 38px;
        left: 0;
        z-index: 0;
        width: 100%;
        padding: 1rem;
        text-align: center;
        background-color: rgba(0,0,0,0.85);
        a {
            display: block;
            padding: 0.5rem 0;
            &.muted {
                color: #565656;
                cursor: default;
                &:hover {
                    color: #565656;
                    &:before {
                        width: 0;
                    }
                }
            }
        }
    }
}
</style>

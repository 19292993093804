<template>
<div id="highlight-options">
  <swiper :slides-per-view="4" :breakpoints='{
"300": {
  "slidesPerView": 1.5,
  "spaceBetween": 10
},
"580": {
  "slidesPerView": 2.5,
  "spaceBetween": 10
},
"768": {
  "slidesPerView": 3.5,
  "spaceBetween": 10
},
"992": {
  "slidesPerView": 4,
  "spaceBetween": 30
}
}'>
    <swiper-slide>
      <div class="slide">
        <div class="card">
          <img class="img-fluid" src="@/assets/highlight_border_top.png" alt="Card image cap">
          <div class="card-body text-center">
            <div class="card-wrapper">
              <h4 class="card-title tan">COLLECT</h4>
              <p class="card-text">Like dreams, no two NFTs are the same. This is your chance to collect all your favorite characters and be ready for the full gaming adventure to come!</p>
            </div>
          </div>
          <img class="img-fluid" src="@/assets/highlight_border_bottom.png" alt="Card image cap">
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="slide">
        <div class="card">
          <img class="img-fluid" src="@/assets/highlight_border_top.png" alt="Card image cap">
          <div class="card-body text-center">
            <div class="card-wrapper">
              <h4 class="card-title tan">EXPLORE</h4>
              <p class="card-text">Immerse yourself in the whimsical story of Clementine. Dive into the lore to gain a deeper understanding of the characters and ever expanding universe.</p>
            </div>
          </div>
          <img class="img-fluid" src="@/assets/highlight_border_bottom.png" alt="Card image cap">
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="slide">
        <div class="card">
          <img class="img-fluid" src="@/assets/highlight_border_top.png" alt="Card image cap">
          <div class="card-body text-center">
            <div class="card-wrapper">
              <h4 class="card-title tan">PLAY</h4>
              <p class="card-text">Character NFTs will be available for all Genesis & Eclipse holders at launch! Use them to conquer your nightmares and team up with friends.</p>
            </div>
          </div>
          <img class="img-fluid" src="@/assets/highlight_border_bottom.png" alt="Card image cap">
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="slide">
        <div class="card">
          <img class="img-fluid" src="@/assets/highlight_border_top.png" alt="Card image cap">
          <div class="card-body text-center">
            <div class="card-wrapper">
              <h4 class="card-title tan">MASTER</h4>
              <p class="card-text">Become a master of the cosmos through knowledge and practice. Show how far you can take your skills across the ethereal plane as you rank up to supreme greatness!</p>
            </div>
          </div>
          <img class="img-fluid" src="@/assets/highlight_border_bottom.png" alt="Card image cap">
        </div>
      </div>
    </swiper-slide>
  </swiper>
</div>
</template>

<script>
import {
  Swiper,
  SwiperSlide
} from 'swiper/vue';
export default {
  components: {
    Swiper,
    SwiperSlide,
  }
};
</script>
<style lang="scss" scoped>
$fullsize: 1799px;
$mobile: 767px;
$tablet: 991px;
#highlight-options {
    margin-top: -13rem;
    padding: 0 2rem;
    @media(min-width: 2000px) {
        margin-top: -17vw;
    }
    @media(max-width: $fullsize) {
        margin-top: -10rem;
    }
    @media(max-width: $tablet) {
        padding: 0;
        margin-top: -6rem;
    }
    @media(max-width: $mobile) {
        margin-top: -4rem;
    }
    .swiper-slide {
        height: auto;
        .slide {
            height: 100%;
            .card {
                height: 100%;
                background-color: transparent;
                .card-body {
                    background: url("../assets/highlight_border_repeat.png") left top;
                    background-size: contain;
                    padding: 1rem 3.5rem 0;
                    @media(max-width: $fullsize) {
                        padding: 1rem 2rem 0;
                    }
                    .card-text {
                        color: #fff;
                    }
                }
            }
        }
    }
}
</style>

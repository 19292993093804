<template>
<section class="hero-block">
  <Hero class="vh-100" title="Unleash Phase 1" titleClass="orange" bkgImage="ntf_phase1_bkg.jpg" bkgImageMobile="mobile_ntf_phase1_bkg.jpg" iconImage="nft_icon.png" bkgColor="#3a1210" offset="100%">
    <div class="draw-line"><span class="title tan">(Private Hall Pass Presale)</span></div>
    <div class="content-block">
      <p>The <span class="orange">GENESIS COLLECTION</span> will launch February, 2022. This will consist of</p>
      <p class="display-2 orange">10 DIFFERENT CHARACTERS</p>
      <p>with a maximum supply of <span class="orange">500</span> unique assets each, totalling <span class="orange">5,000</span> NFT Assets. Hall Pass pre-sale information can be found in our Discord channel.</p>
    </div>
    <div class="draw-line mt-0"><span class="title"><img src="@/assets/line-tan.png" class="img-fluid" /></span></div>
  </Hero>
</section>
<section class="hero-block border-top">
  <Hero class="vh-100" title="Unleash Phase 2" titleClass="pink" bkgImage="ntf_phase2_bkg.jpg" bkgImageMobile="mobile_ntf_phase2_bkg.jpg" iconImage="nft2_icon.png" positionClass="justify-content-end" bkgColor="#161f1c" offset="140%">
    <div class="draw-line"><span class="title tan">(Public)</span></div>
    <div class="content-block">
      <p>The <span class="pink">ECLIPSE COLLECTION</span> details will be released in April 7th, 2022. This phase will feature additional characters not included in Phase One. It will consist of</p>
      <p class="display-2 pink">20 ADDITIONAL CHARACTERS</p>
      <p>with a maximum supply of <span class="pink">500</span> unique assets each and with</p>
      <p class="display-2 pink text-uppercase">10 unique 1 of 1 legendaries</p>
      <p>totaling <span class="pink">10,010</span> NFT Assets.</p>
    </div>
    <div class="draw-line mt-0"><span class="title"><img src="@/assets/line-tan.png" class="img-fluid" /></span></div>
  </Hero>
</section>
</template>

<script>
import Hero from '@/components/Hero.vue'
export default {
  name: 'Nft',
  components: {
    Hero
  }
}
</script>
